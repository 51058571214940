import React from "react";
import styled from "styled-components";
import { SvgLocation } from "melodies-source/Svgs/Location";
import { SvgRecord } from "melodies-source/Svgs/Record";
import { SvgMusic } from "../../melodies-source/Svgs/Music";
import { H3, Caption, Body2, Subtitle2 } from "melodies-source/Text";
import { Hyperlinks } from "Components/Hyperlinks";
import { DateTime } from "luxon";

export const Card = ({ ...show }) => {
  let day = DateTime.fromMillis(show?.startsAt).toFormat("dd");
  let month = DateTime.fromMillis(show?.startsAt).toFormat("LLL");

  return (
    <Container>
      <div style={{ position: "relative" }}>
        <ArtistImage src={show?.image} alt="featured artist" />
        <ShowDate>
          <Month>{month}</Month>
          <Day>{day}</Day>
        </ShowDate>
      </div>
      <Info>
        <H3>{show?.artistName}</H3>
        <span>
          <SvgLocation />
          <Details>
            <Body2>
              {show?.venue?.length > 22
                ? `${show?.venue?.slice(0, 20).trim()}...`
                : show?.venue}
            </Body2>
            <Subtitle2 style={{ fontWeight: 300 }}>
              {show?.displayAddress}
            </Subtitle2>
          </Details>
        </span>
        {show?.audience && (
          <span>
            <SvgRecord />
            <Body2>{show?.audience}</Body2>
          </span>
        )}
        {show?.genre && (
          <span>
            <SvgMusic />
            <Body2>{show?.genre?.replace(",", ", ")}</Body2>
          </span>
        )}
      </Info>
      <Hyperlinks artistLink={null} website={show?.website || ""} />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  background-color: #ffffff;
  box-shadow: 0 0 10px 7px rgba(0, 0, 0, 0.07);
  border-radius: 20px;
  height: 100%;
  max-width: 340px;

  h2 {
    font-size: 36px;
    line-height: 130%;
  }
  span {
    margin-top: 15px;
    display: flex;
    flex-direction: row;
    min-height: 20px;

    svg {
      margin: -1px 10px 0px 0;
      color: #7575da;
      display: flex;
      flex-shrink: 0;
    }
  }
`;
const ArtistImage = styled.img`
  width: 100%;
  border-radius: 20px 20px 0 0;
  object-fit: cover;
  object-position: top center;
  aspect-ratio: 16/9;
`;

const Details = styled.div`
  display: flex;
  flex-direction: column;
  font-weight: 500;
  ${Caption} {
    color: #666666;
  }
`;
const Info = styled.div`
  padding: 20px 30px 50px;
  position: relative;
  ${H3} {
    color: #1b0076;
    padding-right: 30px;
  }
  ${Body2} {
    color: #666666;
    font-size: 13px;
  }
  ${Subtitle2} {
    color: #5c5b5b;
    font-size: 11px;
  }
`;
const ShowDate = styled.div`
  position: absolute;
  right: 10%;
  bottom: -7%;
  width: 20%;
  max-width: 40px;
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.1);

  img {
    width: 80%;
    object-fit: cover;
  }
`;
const Month = styled.div`
  background-color: #7575da;
  color: #fff;
  width: 100%;
  padding: 3px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 3px 3px 0 0;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 12px;
`;
const Day = styled.div`
  background-color: #ffffff;
  color: #666;
  width: 100%;
  font-size: 18px;
  display: flex;
  padding: 3px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 0 0 3px 3px;
  font-weight: 600;
`;

import { BrowserRouter as Router } from "react-router-dom";
import { Theme } from "./melodies-source/Theme";
import { Routes } from "./Routes";
import { UserProvider, useUser } from "utils/auth";
import { AuthHandler } from "utils/authHandler";

function App() {
  const { isLoggedIn, user, signOut, claims } = useUser();
  return (
    <Theme>
      <Router>
        {/* @ts-ignore */}
        <UserProvider>
          <AuthHandler>
            {/* {claims?.admin ? <Routes /> : "User not found"} */}
            <Routes />
          </AuthHandler>
        </UserProvider>
      </Router>
    </Theme>
  );
}

export default App;

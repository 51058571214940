import { Routes as RouterRoutes, Route, useNavigate } from "react-router-dom";
import { Landing } from "./Landing";
import { useEffect } from "react";

export const Routes = () => {
  return (
    <RouterRoutes>
      <Route path="/" index element={<Landing />} />
      <Route path="*" element={<Redirect />} />
    </RouterRoutes>
  );
};

const Redirect = () => {
  const navigate = useNavigate();
  useEffect(() => {
    navigate("/");
  }, []);
  return null;
};

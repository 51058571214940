import { FC, PropsWithChildren } from "react";
import { useUser } from "./auth";

import { sessionApi } from "@musicaudienceexchange/firebase-auth-ui";

export const AuthHandler: FC<PropsWithChildren<{ children: any }>> = ({
  children,
}) => {
  const { isLoggedIn, user, claims } = useUser();

  const isAuthorized =
    isLoggedIn &&
    !user?.isAnonymous &&
    (claims?.admin || claims?.events_search);

  if (isAuthorized) {
    return children;
  } else {
    sessionApi.removeCookie().then(() => {
      const url = new URL(
        `https://${process.env.REACT_APP_FIREBASE_AUTHDOMAIN}/user/login`,
      );
      url.searchParams.set("redirect", window.location.href);
      window.location.href = url.toString();
    });
  }
  return null;
};

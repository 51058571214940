import { initializeApp } from "firebase/app";
import {
  getFirestore,
  doc,
  updateDoc,
  connectFirestoreEmulator,
  addDoc,
  collection,
} from "firebase/firestore";
import { getAuth, connectAuthEmulator } from "firebase/auth";
import {
  getFunctions,
  connectFunctionsEmulator,
  httpsCallable,
} from "firebase/functions";
import {
  initializeAppCheck,
  ReCaptchaEnterpriseProvider,
} from "firebase/app-check";
import type { DocumentReference } from "firebase/firestore";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTHDOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASEURL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECTID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGINGSENDERID,
  appId: process.env.REACT_APP_FIREBASE_APPID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENTID,
};

const app = initializeApp(firebaseConfig);

if (process.env.NODE_ENV === "development") {
  //@ts-ignore
  // eslint-disable-next-line no-restricted-globals
  self.FIREBASE_APPCHECK_DEBUG_TOKEN =
    process.env.REACT_APP_FIREBASE_RECAPTCHADEBUG;
  //@ts-ignore
}

const firestore = getFirestore(app);
const auth = getAuth(app);
const functions = getFunctions(app);

auth.useDeviceLanguage();

export default app;
export {
  firestore,
  auth,
  functions,
};

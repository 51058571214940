import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Card } from "Components/Card";
import { H4 } from "melodies-source/Text";
import styled from "styled-components";
import { MatchedEventDetail } from "Components/SearchDash";
import { DateTime } from "luxon";
import { ParamsType } from "Routes/Landing";
import Pagination from "Components/Pagination";
import { useMediaQuery } from "utils/useMediaQuery";

export const ShowList = ({
  data,
  searchParams,
}: {
  data: MatchedEventDetail[];
  searchParams: ParamsType;
}) => {
  const [eventData, setEventData] = useState<MatchedEventDetail[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const isTablet = useMediaQuery("(min-width: 820px) and (max-width: 1080px)");
  const RESULTS_PER_PAGE = isTablet ? 12 : 16;
  const indexLastPost = currentPage * RESULTS_PER_PAGE;
  const indexFirstPost = indexLastPost - RESULTS_PER_PAGE;

  useEffect(() => {
    setEventData(data);
    setCurrentPage(1);
  }, [data]);

  return (
    <Container>
      <H4>
        Showing {eventData?.length} shows within {searchParams?.range || 100}{" "}
        miles of {searchParams?.location} from{" "}
        {DateTime.fromISO(searchParams?.dateFrom).toFormat("LLL dd yyyy")}-{" "}
        {DateTime.fromISO(searchParams?.dateTo).toFormat("LLL dd yyyy")}
      </H4>
      <ShowWrapper>
        {eventData?.slice(indexFirstPost, indexLastPost)?.map((show: any) => {
          return <Card {...show} key={show?.id} />;
        })}
      </ShowWrapper>
      {eventData?.length > RESULTS_PER_PAGE && (
        <Pagination
          selectedPage={currentPage}
          resultsPerPage={RESULTS_PER_PAGE}
          totalResults={eventData?.length}
          onSetPage={setCurrentPage}
        />
      )}
    </Container>
  );
};

const Container = styled.div`
  display: grid;
  width: 95%;
  margin: 0 auto;
  max-width: 1450px;
`;
const ShowWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  width: 100%;
  column-gap: 25px;
  row-gap: 25px;
  margin: 50px 0;
  @media (min-width: 800px) and (max-width: 1180px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
`;

import React, { useState } from "react";
import styled from "styled-components";
import { decode } from "ngeohash";
import { Button } from "melodies-source/Button";
import { Datepicker } from "melodies-source/DatePicker";
import {
  AutocompleteAsync as StyledAutocomplete,
  Option as OptionType,
} from "melodies-source/Autocomplete";
import {
  Radio as StyledRadio,
  Label as RadioLabel,
} from "melodies-source/Selectable";
import {
  RadioContainer,
  RadioSelect,
} from "melodies-source/Selectable/RadioInput";
import { Client } from "typesense";
import { DateTime } from "luxon";
import { getNode, getNodes } from "Components/Context/SearchContext";
import { SearchParams } from "typesense/lib/Typesense/Documents";
import sourceData from "Components/cities.json";
import { ParamsType } from "Routes/Landing";

export interface MatchedEventDetail {
  address: string;
  artistName: string;
  id: string;
  image: string;
  startsAt: number;
  deletedAt: number;
  venue: string;
  displayAddress: string;
  distance_meters?: number;
  geo_distance_meters?: number;
  audience?: string;
  genre?: string;
  isNearby?: boolean;
  version?: number;
  coordinates?: number[];
  label?: string;
  maxArtistId?: number;
  website?: string;
}

export interface CityType {
  city: string;
  geo: string;
  label: string;
  pop: number;
  state: string;
  value: string;
}
export interface Coordinates {
  latitude: number;
  longitude: number;
}

interface setData {
  setData: (v: MatchedEventDetail[]) => void;
  setSearchParams: (v: ParamsType) => void;
  setNoData: (v: boolean) => void;
}

const client = new Client({
  //@ts-ignore
  nodes: getNodes(process.env.REACT_APP_SEARCH_SERVER),
  nearestNode: getNode(process.env.REACT_APP_SEARCH_SERVER_NEAREST),
  apiKey: process.env.REACT_APP_TYPESENSE_KEY as string,
  // logLevel: process.env.REACT_APP_ENV === "production" ? "silent" : "debug",
});

export const SearchDash = ({
  setData,
  setSearchParams,
  setNoData,
}: setData) => {
  const [range, setRange] = useState<number | null>(null);
  const [dateFrom, setDateFrom] = useState<DateTime | null>(null);
  const [dateTo, setDateTo] = useState<DateTime | null>(null);
  const [text, setText] = useState<string | null>(null);
  const [cityData, setCityData] = useState<CityType>();

  const queryEvents = async (
    queryParams: SearchParams,
    radiusLimitCoords?: Coordinates,
    range?: number
  ) => {
    let filter_by = "deletedAt:0";
    if (radiusLimitCoords) {
      if (filter_by) filter_by += "&&";
      filter_by = filter_by.concat(
        `coordinates:(${radiusLimitCoords?.latitude},${radiusLimitCoords?.longitude},${range} mi)`
      );
    }
    if (dateFrom && dateTo) {
      if (filter_by) filter_by += "&&";
      filter_by = filter_by.concat(
        `startsAt:>=${dateFrom?.toMillis()} && startsAt:<=${dateTo?.toMillis()}`
      );
    }
    try {
      const result = await client
        .collections<MatchedEventDetail>("setlive_events")
        .documents()
        .search({
          //page: currentPage,
          per_page: 250,
          ...queryParams,
          filter_by,
        });

      if (result?.hits?.length) {
        setNoData(false);
        return result.hits;
      } else {
        setNoData(true);
        return [];
      }
    } catch (err) {
      console.error(err);
    }
  };

  const handleDataComplete = async () => {
    if (!cityData || !dateFrom || !dateTo) return;
    let obj = Object.assign({
      location: cityData?.city,
      range,
      dateFrom,
      dateTo,
    });

    setSearchParams(obj);
    const coords = decode(cityData?.geo);

    const queriedEvents = await queryEvents(
      {
        q: "*",
        query_by: "artistName,venue",
        sort_by: "startsAt:asc",
      },
      coords,
      range || 100
    );

    const searchResults = queriedEvents?.map((e: any) => e.document) || [];

    if (searchResults) {
      setData(searchResults);
    }
  };

  const data = sourceData
    .map((city) => ({
      ...city,
      label: `${city.city}, ${city.state}`,
      value: city.geo + city.city,
    }))
    .sort((a, b) => b.pop - a.pop);

  const searchLocation = async (searchTerm: string) => {
    if (!searchTerm) return [];
    const result = data
      .filter(({ city }) =>
        city.toLowerCase().includes(searchTerm.toLowerCase())
      )
      .sort((a, b) => b.pop - a.pop)
      .slice(0, 5);
    return result as unknown as OptionType[];
  };

  return (
    <Container>
      <SearchWrapper>
        <Autocomplete
          text={text!}
          setText={setText}
          //@ts-ignore
          value={cityData?.label}
          getOptions={searchLocation}
          onChange={(v: any) => setCityData(v)}
          label="City"
          placeholder="Search for a city..."
          style={{ width: "100%" }}
        />

        <Datepicker
          value={dateFrom || null}
          label="From"
          onChange={(v) => setDateFrom(v.set({ hour: 0, minute: 0 }))}
        />
        <Datepicker
          value={dateTo || null}
          label="To"
          onChange={(v) => setDateTo(v.set({ hour: 23, minute: 59 }))}
        />
        <Button
          style={{ padding: "0 40px", height: 41, cursor: "pointer" }}
          variant="secondaryPrimary"
          onClick={handleDataComplete}
        >
          Search
        </Button>
      </SearchWrapper>
      <Range>
        <Radio
          label="50 Miles"
          value={range === 50}
          onChange={() => setRange(50)}
        />
        <Radio
          label="100 Miles"
          value={range === 100}
          onChange={() => setRange(100)}
        />
        <Radio
          label="500 Miles"
          value={range === 500}
          onChange={() => setRange(500)}
        />
      </Range>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  margin: 30px auto;
  padding: 0 20px;
  max-width: 1000px;
  gap: 10px;
  color: #333333;
`;
const SearchWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  width: 100%;
  gap: 10px;
`;
const Range = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 30px;
`;
const Radio = styled(StyledRadio)`
  color: #333333;
  ${RadioLabel} {
    color: #333333;
  }
  ${RadioContainer} {
    border: 1px solid #1b0076;
  }
  ${RadioSelect} {
    background: #1b0076;
  }
`;
const Autocomplete = styled(StyledAutocomplete)`
  & > div > div {
    label {
      color: #000000 !important;
    }
  }
`;

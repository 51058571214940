import {
  CreateAuthentication,
  defaultLoadClaims,
} from "@musicaudienceexchange/firebase-auth-ui";
import "./firebase";

export const { useUser, UserContext, UserProvider } = CreateAuthentication({
  firebaseAuthDomain: process.env.REACT_APP_FIREBASE_AUTHDOMAIN,
  loadClaims: (data) => ({
    ...defaultLoadClaims(data),
    events_search: data.events_search === true,
  }),
});

import { useState } from "react";
import styled from "styled-components";
import { ReactComponent as MaxSvg } from "../Images/MaxSvg.svg";
import { H4 } from "melodies-source/Text";
import { useUser } from "utils/auth";
import { useClickOutside } from "melodies-source/utils/hooks";

export const Menu = () => {
  const { user, signOut } = useUser();
  const [open, setOpen] = useState(false);
  const [referenceElement, setReferenceElement] =
    useState<HTMLDivElement | null>(null);
  useClickOutside(referenceElement, () => setOpen(false));

  return (
    <Container>
      <Left>
        <MaxSvg />
        <Header>Sponsorship Opportunity Search</Header>
      </Left>
      <Right>
        <UserAvatar onClick={() => setOpen(!open)} ref={setReferenceElement}>
          {user?.displayName?.slice(0, 1) || "M"}
        </UserAvatar>
        {open && (
          <DropdownMenu>
            <Option onClick={() => signOut()}>Sign Out</Option>
            <Info>
              {user?.displayName} &lt;{user?.email}&gt;
            </Info>
          </DropdownMenu>
        )}
      </Right>
    </Container>
  );
};
const Container = styled.div`
  padding: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: inset 0 -1px 0 0 #e6e9eb;
  background: white;
  z-index: 10;
  position: relative;
`;

const Left = styled.div`
  display: flex;
  align-items: center;
`;

const Right = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const Header = styled(H4)`
  color: #7575da;
  margin-left: 10px;
`;

const UserAvatar = styled.div`
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  font-family: Poppins;
  font-size: 16.5px;
  letter-spacing: 0;
  line-height: 25px;
  text-align: center;
  background: #3795bd;
  border-radius: 50%;
  cursor: pointer;
  user-select: none;
`;

const DropdownMenu = styled.div`
  width: 300px;
  position: absolute;
  top: 100%;
  right: 0;
  z-index: 100;
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.16);
  background: #fff;
`;

const Option = styled.a`
  display: block;
  text-decoration: none;
  font-family: Poppins;
  padding: 8px;
  cursor: pointer;
  user-select: none;
  &:hover {
    background: #e6e9eb;
  }
`;

const Info = styled.div`
  font-family: Poppins;
  padding: 8px;
  font-size: 12px;
`;

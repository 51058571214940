import styled from "styled-components";
import { Button as StyledButton } from "melodies-source/Button/index";
import { ReactComponent as GlobeSvg } from "Components/Images/globe.svg";
import { ReactComponent as UserSvg } from "Components/Images/user.svg";
import { css } from "styled-components";
import { useState } from "react";

export const Hyperlinks = ({ artistLink, website }: any) => {
  const [open, setOpen] = useState(false);
  return (
    <Container
      onMouseEnter={() => setOpen(true)}
      onMouseLeave={() => setOpen(false)}
    >
      <Button
        variant="secondary"
        inactive={!website?.length}
        onClick={() => window.open(website, "_blank", "noopener")}
      >
        <GlobeSvg />
      </Button>
      <TooltipContainer>
        {open && <Tooltip>Artist website</Tooltip>}
      </TooltipContainer>
      {/* <Button
        variant="secondary"
        inactive={!artistLink?.length}
        onClick={() => window.open(artistLink, "_blank", "noopener")}
      >
        <UserSvg />
      </Button> */}
    </Container>
  );
};

const Container = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: row;
  margin: 20px;
`;
const TooltipContainer = styled.div`
  width: 100%;
  position: relative;
`;
const Tooltip = styled.div`
  width: 150px;
  position: absolute;
  right: -20%;
  top: 102%;
  border-radius: 3px;
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  z-index: 20;
  display: flex;
  height: 25px;
  align-items: center;
  justify-content: center;
`;
const Button = styled(StyledButton)<{
  inactive?: boolean;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
}>`
  color: #7575da;
  line-height: 20px;
  height: auto;
  padding: 0;
  border: none;
  width: auto;
  svg {
    height: 23px;
    width: 23px;
  }
  & + button {
    margin-left: 13px;
  }
  &:hover {
    transform: scale(1.1);
  }
  & > svg:hover {
    box-shadow: 0 0px 0px 0 rgba(0, 0, 0, 0) !important;
  }
  ${({ inactive }) =>
    inactive &&
    css`
      pointer-events: none;
      svg > g > path {
        fill: #7575da !important;
      }
    `}
`;

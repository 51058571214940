import { DateTime } from "luxon";
import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { Dropdown as DropdownBase } from "../Dropdown";
import { SvgCalendar } from "../Svgs/Calendar";
import { SvgLeft } from "../Svgs/Left";
import { SvgRight } from "../Svgs/Right";
import { Body2, Caption, Label, Selected } from "../Text";

const CaptionGray = styled(Caption)`
  color: #999999;
`;

const Body2Gray = styled(Body2)`
  color: #666666;
  font-size: 15px;
`;

const CalendarIcon = styled(SvgCalendar)`
  color: #999999;
  margin: -1px 6px 1px 0;
`;

const Dropdown = styled(DropdownBase)`
  margin-top: 9px;
  box-shadow: 0 0px 8px 0 rgba(0, 0, 0, 0.14);
  padding: 22px 19px;
  width: 320px;
  border-radius: 12px;
`;
const ActionWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #333333;
`;
const ActionIcon = styled.div`
  color: #333333;
  border-radius: 100%;
  padding: 5px;
  :hover {
    cursor: pointer;
    background: ${(p) => p.theme.colors.gray2};
  }
`;

const MonthDateWrapper = styled.div`
  //centered
  display: flex;
  align-items: center;
  justify-content: center;
  height: 36px;
  width: 36px;
  margin: 2px;
`;

type ValueProps = {
  isPickerOpen: boolean;
  hasError?: boolean;
};
const Value = styled.div<ValueProps>`
  border-radius: 5px;
  background: ${(p) => p.theme.colors.white};
  border: solid 1px ${(p) => p.theme.colors.black20};
  border-radius: 6px;
  padding: 10px 12px;
  width: 180px;
  ${(p) =>
    p.isPickerOpen &&
    css`
      box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.24);
    `}
  ${(p) =>
    p.hasError &&
    css`
      border: solid 2px ${(p) => p.theme.colors.error};
    `}
`;

type DayContainerProps = {
  isPast?: boolean;
  isSelected?: boolean;
  isToday?: boolean;
};
const MonthDateContainer = styled(MonthDateWrapper)<DayContainerProps>`
  aspect-ratio: 1;
  border-radius: 100%;
  color: ${(p) => p.theme.colors.black80};

  ${(p) =>
    p.isPast &&
    css`
      color: ${(p) => p.theme.colors.black20};
    `}
  ${(p) =>
    p.isToday &&
    css`
      color: ${(p) => p.theme.colors.navy};
      font-weight: 600;
    `};
  ${(p) =>
    p.isSelected
      ? css`
          background-color: ${p.theme.colors.navy};
          color: ${(p) => p.theme.colors.white};
        `
      : css`
          :hover {
            background-color: ${(p) => p.theme.colors.gray2};
          }
        `};
`;

const days = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];
const weekHeaderUI = (
  <div style={{ display: "flex", margin: "17px 0 0 0px" }}>
    {days.map((day, index) => (
      <div
        style={{
          height: 24,
          margin: "0 auto",
          color: "#666666",
        }}
        key={index}
      >
        <CaptionGray>{day}</CaptionGray>
      </div>
    ))}
  </div>
);

type Props = {
  value?: DateTime | null;
  label?: string;
  onChange: (v: DateTime) => void;
  minDate?: DateTime;
  hasError?: boolean;
  isRangePicker?: boolean;
};
export const Datepicker = ({
  label,
  value,
  onChange,
  hasError,
  minDate,
  ...props
}: Props) => {
  const [refDate, setRefDate] = useState<DateTime>(value || DateTime.now());
  const [isPickerOpen, setIsPickerOpen] = useState(false);

  const monthFirstDay = refDate.startOf("month");
  const monthLastDay = refDate.endOf("month");

  const monthDates = [];
  for (let i = 0; i < monthFirstDay.weekday; i++) monthDates.push(null);
  for (let d = 0; d < refDate.daysInMonth; d++)
    monthDates.push(monthFirstDay.plus({ days: d }));

  useEffect(() => {}, []);

  const gotoNextMonth = () => setRefDate(monthLastDay.plus({ days: 1 }));
  const gotoPreviousMonth = () => setRefDate(monthFirstDay.minus({ days: 1 }));
  const monthHeaderUI = (
    <ActionWrap>
      <ActionIcon onClick={gotoPreviousMonth}>
        <SvgLeft />
      </ActionIcon>
      <Selected>
        {refDate.monthLong} {refDate.year}
      </Selected>
      <ActionIcon onClick={gotoNextMonth}>
        <SvgRight />
      </ActionIcon>
    </ActionWrap>
  );

  const valueUI = (
    <>
      {label && (
        <div>
          <Label>{label}</Label>
        </div>
      )}
      <Value
        hasError={hasError}
        // clickable
        isPickerOpen={isPickerOpen}
        onClick={() => setIsPickerOpen(true)}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            gap: "5px",
          }}
        >
          <CalendarIcon />
          <div>
            {value ? value.toFormat("DD") : <Body2Gray>Pick a date…</Body2Gray>}
          </div>
        </div>
      </Value>
    </>
  );

  return (
    <div {...props}>
      {valueUI}
      <Dropdown
        placement="bottom-start"
        isOpen={isPickerOpen}
        onClose={() => setIsPickerOpen(false)}
      >
        <div>
          {monthHeaderUI}
          {weekHeaderUI}
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              width: "100%",
            }}
          >
            {monthDates.map((date, index) => (
              <MonthDateWrapper key={index}>
                {date && (
                  <MonthDate
                    {...{
                      date,
                      value,
                      minDate,
                      onChange,
                      setIsPickerOpen,
                    }}
                  />
                )}
              </MonthDateWrapper>
            ))}
          </div>
        </div>
      </Dropdown>
    </div>
  );
};

type MonthDateProps = {
  date: DateTime;
  setIsPickerOpen: React.Dispatch<React.SetStateAction<boolean>>;
} & Pick<Props, "value" | "onChange" | "minDate">;
const MonthDate = ({
  value,
  date,
  setIsPickerOpen,
  minDate,
  onChange,
}: MonthDateProps) => {
  const isSelected = date.toISODate() === value?.toISODate();
  const isToday = date.toISODate() === DateTime.local().toISODate();
  const isPast = minDate ? date < minDate.startOf("day") : false;

  const onSelect = () => {
    if (isPast) return;
    onChange(date);
    setIsPickerOpen(false);
  };

  return (
    <MonthDateContainer onClick={onSelect} {...{ isSelected, isToday, isPast }}>
      {date.day}
    </MonthDateContainer>
  );
};

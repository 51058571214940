import { Menu } from "../../Components/Menu";
import React, { useState } from "react";
import styled from "styled-components";
import backdropImg from "../../Components/Images/ShowSearchBG.jpg";
import { SearchDash } from "Components/SearchDash";
import { ShowList } from "Components/ShowList";
import { LoadingSpinner } from "Components/LoadingSpinner";
import { MatchedEventDetail } from "Components/SearchDash";
import { useMediaQuery } from "utils/useMediaQuery";
import { SvgSearchAlt } from "melodies-source/Svgs/SearchAlt";
import { Body2, H2, H3, H4 } from "melodies-source/Text";
import { css } from "styled-components";

export interface ParamsType {
  location: string;
  range: number | null;
  dateFrom: any;
  dateTo: any;
}

export const Landing = () => {
  const isMobile = useMediaQuery("(max-width: 819px)");
  const [data, setData] = useState<MatchedEventDetail[]>();
  const [noData, setNoData] = useState<boolean>(false);
  const [searchParams, setSearchParams] = useState<ParamsType>({
    location: "",
    range: null,
    dateFrom: null,
    dateTo: null,
  });

  return (
    <Container>
      <Menu />
      {!isMobile && (
        <>
          <Body>
            <SearchDash
              setData={setData}
              setSearchParams={setSearchParams}
              setNoData={setNoData}
            />

            {!!data?.length && (
              <Content>
                <ShowList data={data} searchParams={searchParams} />
              </Content>
            )}
          </Body>
          {noData && (
            <MessageContainer nodata>
              <SvgSearchAlt />
              <H2>
                We have yet to book any shows for this area and date range
              </H2>
              <H4>Please try different terms and search again</H4>
            </MessageContainer>
          )}
        </>
      )}
      {(!data || isMobile) && (
        <Backdrop src={backdropImg} alt="background concert image" />
      )}
      {isMobile && (
        <MessageContainer>
          <SvgSearchAlt />
          <H3>Please search on a computer or tablet</H3>
          <Body2>This experience is optimized for larger screens</Body2>
        </MessageContainer>
      )}
    </Container>
  );
};
const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;
const Body = styled.div`
  display: flex;
  flex-direction: column;
  z-index: 5;
  background-color: #f3f7f9;
  width: 100%;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  z-index: 5;
  height: 100vh;
  padding-top: 50px;
  background-color: #ffffff;
`;
const Backdrop = styled.img`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
const MessageContainer = styled.div<{ nodata?: boolean }>`
  display: flex;
  flex-direction: column;
  z-index: 4;
  padding-top: 50px;
  width: 60%;
  margin: 0 auto;
  align-items: center;
  justify-content: flex-start;
  text-align: center;
  gap: 20px;
  max-width: 500px;
  h3 {
    color: #1b0076;
  }
  p {
    color: #666666;
  }
  svg {
    width: 40px;
    height: 40px;
    color: #ccc0f1;
  }
  ${({ nodata }) =>
    nodata &&
    css`
      svg {
        width: 70px;
        height: 70px;
      }
    `}
`;

import React from "react";
import styled, { css } from "styled-components";

type TextProps = {
  disabled?: boolean;
  hasError?: boolean;
};

export const H1 = styled.h1`
  font-family: var(--max-font-primary);
  font-size: 38px;
  font-weight: 600;
  line-height: 54px;
`;

export const Metric = styled.h1`
  font-family: var(--max-font-primary);
  font-size: 38px;
  font-weight: 700;
  line-height: 54px;
`;

export const H2 = styled.h2`
  font-family: var(--max-font-primary);
  font-size: 26px;
  font-weight: 600;
  line-height: 36px;
`;

export const H3 = styled.h3`
  font-family: var(--max-font-primary);
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
`;

export const H4 = styled.h4`
  font-family: var(--max-font-primary);
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
`;

export const Subtitle1 = styled.h4`
  font-family: var(--max-font-primary);
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
`;

export const Subtitle2 = styled.h4`
  font-family: var(--max-font-primary);
  font-size: 11px;
  font-weight: 600;
  line-height: 16px;
`;

export const Caption = styled.p<TextProps>`
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  color: #ffffff;
  ${({ hasError, theme }) =>
    hasError &&
    css`
      color: ${theme.colors.error};
    `}
`;

export const UserText = styled.p<TextProps>`
  font-family: var(--max-font-secondary);
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  ${({ disabled }) =>
    disabled &&
    css`
      color: #999999;
    `}
`;

export const Selected = styled.p<TextProps>`
  font-family: var(--max-font-primary);
  font-size: 15;
  font-weight: 600;
  line-height: 22px;
  ${({ disabled }) =>
    disabled &&
    css`
      color: #999999;
    `}
`;

export const LinkText = styled.p`
  font-family: var(--max-font-primary);
  font-size: 16;
  font-weight: 600;
  line-height: 24px;
`;

export const Li = styled.p<TextProps>`
  font-size: 15px;
  font-weight: 500;
  line-height: 21px;
  ${({ disabled }) =>
    disabled &&
    css`
      color: #999999;
    `}
`;

export const Body1 = styled.p`
  font-family: var(--max-font-primary);
  font-size: 15px;
  font-weight: 400;
  line-height: 22px;
`;

export const Body2 = styled.p`
  font-family: var(--max-font-primary);
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
`;

export const Td = styled.p`
  font-family: var(--max-font-primary);
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
`;

export const Label = styled.label<TextProps>`
  display: block;
  font-family: var(--max-font-primary);
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  ${({ disabled }) =>
    disabled &&
    css`
      color: #999999;
    `}
`;

export const Error = styled.p`
  font-family: var(--max-font-primary);
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  color: #ff0000;
`;
